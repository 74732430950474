@use '../abstracts/'as *;

.main_page {
  position: relative;
  margin-top: 80px;

  &__game {
    height: calc(100vh - 80px);
  }

  .markerWrap {
    position: relative;
    height: 100vh;

    img {
      @media (min-width: 700px) {
        width: 100vw;
        max-width: 100%;
      }

      height: 100%;
      max-width: fit-content;
      // object-fit: cover;
    }

    // figure {
    //   line-height: 0;
    //   position: relative;

    //   img {
    //     width: 100%;
    //     height: 100vh;
    //     // object-fit: cover;
    //     object-fit: fill;
    //   }
    // }
  }

  // :global(.react-transform-wrapper),
  // :global(.react-transform-component) {
  //   width: 100% !important;
  //   display: block !important;
  //   position: relative;
  // }

  .stoneBtn {
    position: absolute;
    height: 6px;
    width: 6px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: center;
    cursor: pointer;
    border: none;
    background-color: transparent;

    img {
      transition: opacity 0.2s ease-in-out;
      width: fit-content;
      height: 100%;
      animation: loading 2.2s linear infinite;
    }
  }

  :global(.MuiTouchRipple-root) {
    display: none;
  }
}

@keyframes loading {
  0% {
    transform: rotateY(0deg);
  }

  100% {
    transform: rotateY(360deg);
  }
}

.location {
  position: absolute;
  right: 70px;
  top: 100px;
  z-index: 3;

  @include mobile {
    right: 0px;
    top: 40px;
  }

  @media (min-width: 2000px) {
    right: 4.375vw;
    top: 6.25vw;
  }

  .locBtn {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    position: relative;

    img {
      @media (min-width: 2000px) {
        height: 5.1875vw;
        width: 18vw;
      }
    }

    div {
      width: 62%;
      height: auto;
      cursor: pointer;
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: absolute !important;
      right: 90px;
      text-transform: uppercase;
      color: #d3cfc9;
      margin: 0;
      font-weight: 700;
      text-shadow: 1px 1px #000;
      font-family: 'Rajdhani', sans-serif;
      font-size: 17px;

      @media (min-width: 2000px) {
        right: 5.625vw;
        font-size: 1.0625vw;
      }
    }

    .btnCom {
      position: absolute !important;
      color: #ec911e;
      font-size: 32px;
      font-weight: bold;
      font-family: 'Rajdhani', sans-serif;
      padding: 6px 10px;
      display: block;
      min-width: 1px;
      line-height: 16px;

      @media (min-width: 2000px) {
        font-size: 2vw;
        padding: 0.375vw 0.625vw;
        line-height: 1vw;
      }

      &.plusBtn {
        right: 22px;
        top: 12px;

        @media (min-width: 2000px) {
          right: 1.375vw;
          top: 0.75vw;
        }
      }

      &.minusBtn {
        right: 26px;
        bottom: 12px;

        @media (min-width: 2000px) {
          right: 1.625vw;
          bottom: 0.75vw;
        }
      }
    }
  }
}

.inventory {
  position: absolute;
  right: 70px;
  bottom: 20px;
  z-index: 3;

  @include mobile {
    right: 0;
    bottom: 80px;
  }

  @media (min-width: 2000px) {
    right: 4.375vw;
    bottom: 1.25vw;
  }

  button {
    position: relative;
    padding: 0;

    &:hover {
      background-color: transparent;
    }

    img {
      @media (min-width: 2000px) {
        height: 5.1875vw;
        width: 18vw;
      }
    }

    p {
      position: absolute;
      right: 100px;
      text-transform: uppercase;
      color: #d3cfc9;
      margin: 0;
      font-weight: 700;
      text-shadow: 1px 1px #000;
      font-family: 'Rajdhani', sans-serif;
      font-size: 17px;

      @media (min-width: 2000px) {
        right: 6.25vw;
        font-size: 1.0625vw;
      }
    }
  }
}

.character {
  position: absolute;
  left: 110px;
  bottom: 35px;
  z-index: 3;
  width: 300px;


  @include mobile {
    left: 50px;
  }

  @media (min-width: 2000px) {
    left: 6.875vw;
    bottom: 1.25vw;
    width: 18.75vw;
  }

  .mainCharater {
    position: relative;
    z-index: 9;

    .resting {
      display: flex;
      align-items: center;
      gap: 20px;
      padding-left: 10px;

      span {
        color: #d3cfc9;
        font-size: 16px;
        font-family: 'Rajdhani', sans-serif;
        font-weight: 700;
      }
    }

    .blackbtnsGroup {
      margin-top: 5px;
      margin-left: -12px;

      @media (min-width: 2000px) {
        margin-top: 0.3125vw;
        margin-left: -0.75vw;
      }

      .frameBtn {
        position: relative;
        display: inline-block;

        &:not(:last-child) {
          margin-right: 5px;

          @media (min-width: 2000px) {
            margin-right: 0.3125vw;
          }
        }

        b {
          position: absolute;
          top: 46%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #d3cfc9;
          font-weight: 700;
          text-shadow: 1px 1px #000;
          font-family: 'Rajdhani', sans-serif;
          font-size: 23px;
          line-height: 1;

          @media (min-width: 2000px) {
            font-size: 1.4375vw;
          }
        }

        img {
          width: 3.125vw;
          height: 3.125vw;
        }
      }
    }

    button {
      position: relative;
      padding: 0;

      &:hover {
        background-color: transparent;
      }

      img {
        @media (min-width: 2000px) {
          width: 15.3125vw;
          height: 3.625vw;
        }
      }

      .avatarImg {
        position: absolute;
        left: -51px;
        top: 55%;
        z-index: 11;
        transform: translateY(-50%);

        @media (min-width: 2000px) {
          left: -3.1875vw;
          top: 50%;
        }

        img {
          @media (min-width: 2000px) {
            width: 5vw;
            height: 5vw;
          }
        }
      }

      p {
        position: absolute;
        left: 37px;
        text-transform: uppercase;
        color: #d3cfc9;
        font-weight: 700;
        text-shadow: 1px 1px #000;
        margin-bottom: 20px;
        font-family: 'Rajdhani', sans-serif;
        font-size: 17px;

        @media (min-width: 2000px) {
          left: 2.3125vw;
          margin-bottom: 1.25vw;
          font-size: 1.0625vw;
        }
      }
    }

    .characterProgressBar {
      width: 91%;
      height: 20px;
      position: absolute;
      z-index: 1;
      bottom: 2px;
      left: 3px;

      @media (min-width: 2000px) {
        height: 1.25vw;
        bottom: 0.125vw;
        left: 0.1875vw;
      }

      span {
        height: 20px;
        background-size: 100% 19px !important;

        @media (min-width: 2000px) {
          height: 1.25vw;
          background-size: 100% 1.1875vw !important;
        }
      }

      label {
        position: absolute;
        text-transform: uppercase;
        color: #d3cfc9;
        font-weight: 700;
        text-shadow: 1px 1px #000;
        right: 15px;
        font-size: 14px;
        font-family: 'Rajdhani', sans-serif;
        line-height: 1.5;

        @media (min-width: 2000px) {
          right: 0.9375vw;
          font-size: 0.875vw;
        }
      }
    }
  }

  .subCharacters {
    z-index: 1;
    // padding-right: 50px;
    position: absolute;
    left: 0;
    bottom: 80px;

    @media (min-width: 2000px) {
      bottom: 10.3125vw;
      left: -0.875vw;
    }

    button {
      p {
        font-size: 14px;
        margin-bottom: 18px;

        @media (min-width: 2000px) {
          font-size: 0.875vw;
          margin-bottom: 1.125vw;
        }
      }
    }

    .avatarImg {
      left: -43px !important;

      @media (min-width: 2000px) {
        left: -2.6875vw !important;
      }

      .charNumber {
        position: absolute;
        width: 22px;
        height: 22px;
        bottom: 10px;
        z-index: 1;
        left: 5px;
        color: #d3cfc9;
        font-weight: 600;
        font-size: 12px;

        @media (min-width: 2000px) {
          width: 1.375vw;
          height: 1.375vw;
          bottom: 0.625vw;
          left: 0.3125vw;
          font-size: 0.75vw;
        }

        &::after {
          position: absolute;
          border: 2px solid #e49317;
          background: #000;
          width: 22px;
          height: 22px;
          content: '';
          left: 0;
          z-index: -1;
          top: -1px;
          transform: rotate(45deg);

          @media (min-width: 2000px) {
            width: 1.375vw;
            height: 1.375vw;
            top: -0.0625vw;
            border: 0.125vw solid #e49317;
          }
        }
      }
    }

    .mainCharater {
      &:not(:last-child) {
        margin-bottom: 20px;

        @media (min-width: 2000px) {
          margin-bottom: 1.25vw;
        }
      }

      .characterProgressBar {
        bottom: 3px;
        height: 16px;

        @media (min-width: 2000px) {
          bottom: 0.1875vw;
          height: 1vw;
        }

        span {
          height: 16px;
          background-size: 100% 16px !important;

          @media (min-width: 2000px) {
            height: 1vw;
            background-size: 100% 1vw !important;
          }
        }

        label {
          font-size: 12px;
          line-height: 1.5;

          @media (min-width: 2000px) {
            font-size: 0.75vw;
          }
        }
      }

      button {
        img {
          @media (min-width: 2000px) {
            width: 13vw;
            height: 3.125vw;
          }
        }
      }

      .avatarImg {
        img {
          @media (min-width: 2000px) {
            width: 4.25vw;
            height: 4.25vw;
          }
        }
      }
    }
  }
}