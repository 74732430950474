@use '../abstracts/'as *;



.action-btn {

    position: relative;
    overflow: hidden;
    font-weight: 700;
    background: linear-gradient(89.96deg, rgba(255, 255, 255, 0.05) 0.03%, rgba(255, 255, 255, 0.008) 49.67%, rgba(255, 255, 255, 0.05) 99.96%);
    backdrop-filter: blur(4px);
    padding: 5px;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    text-align: center;

    border: 1px solid rgba(255, 255, 255, 0.1);

    span {
        // background: linear-gradient(264.28deg, #DEC7FF -38.2%, var(--primary-color) 103.12%);
        background: url('../../assets/images/buttonbg.png') no-repeat center;
        background-size: 200%;
        padding: 10px 20px;
        border-radius: 10px;
        color: #fff;

        transition: all 0.3s ease-in-out;
        position: relative;
        overflow: hidden;

        &::after {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0.13) 0px, rgba(255, 255, 255, 0.13) 77%, rgba(255, 255, 255, 0.5) 92%, rgba(255, 255, 255, 0));
            content: "";
            height: 200%;
            left: -210%;
            opacity: 0;
            position: absolute;
            top: -50%;
            transition: all 0.7s ease 0s;
            width: 200%;
        }
    }

    &:hover {
        span {
            &::after {
                left: -30%;
                opacity: 1;
                top: -20%;
                transition-duration: 0.7s, 0.7s, 0.15s;
                transition-property: left, top, opacity;
                transition-timing-function: linear;
            }
        }
    }

    &--full-width {
        display: block;
        width: 100%;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 0;
            width: 100%;
            text-transform: uppercase;
        }
    }

    .reset-icon {
        margin-left: 10px;
        width: 20px;
        height: 20px;
    }
}

.action-btn.s1 {
    span {
        background: linear-gradient(264.28deg, rgba(255, 255, 255, 0) -38.2%, rgba(255, 255, 255, 0.1) 103.12%);
    }

    &:hover {
        span {
            color: #EC911E;
        }
    }

    &:disabled {
        cursor: not-allowed;

        &:hover {
            span {
                color: initial;
            }
        }

    }
}

#scroll-top {
    position: fixed;
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    border-radius: 4px;
    text-align: center;
    z-index: 999;
    right: 14px;
    bottom: 23px;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    cursor: pointer;
    overflow: hidden;
    background: url('../../assets/images/buttonbg.png') no-repeat center;
    background-size: auto;

    @include flex(center, center);


    span {
        transform: rotate(-180deg);
        display: block;
        font-size: 12px;
    }
}

#scroll-top:hover {
    transform: translateY(-7%);
}