@use '../abstracts/'as *;

.modalMian {
  max-width: 768px;
  margin: auto;

  @media (min-width: 2000px) {
    max-width: 48vw;
  }

  :global(.MuiBackdrop-root) {
    display: none;
  }

  :global(.MuiDialog-paper) {
    // background-color: transparent;
    position: relative;
    box-shadow: none;
    margin: 0;
    border-radius: 0;
    width: 100%;

    @media (min-width: 2000px) {
      max-width: 48vw;
    }
  }

  .modalWrapper {
    background: url("../../assets/images/modalbg.png") no-repeat;
    background-size: cover;
    height: 100%;
    overflow-y: auto;
    padding: 0 15px 20px;


    @media (min-width: 2000px) {
      height: 30.9375vw;
      padding: 0.9375vw 1.25vw;
    }

    .modalHeader {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      text-align: center;

      h3 {
        position: absolute;
        right: 0;
        left: 0;
        padding: 15px;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 0.05em;
        font-family: 'Rajdhani', sans-serif;
        z-index: 1;


        @media (min-width: 2000px) {
          font-size: 1.25vw;
        }
      }

      button {
        position: absolute;
        right: 10px;
        top: 10px;
        background-color: transparent;
        padding: 13px 17px;
        border: none;
        background: url("../../assets/images/btnbg.png") no-repeat;
        color: #9d978b;
        font-weight: 700;
        background-size: contain;
        z-index: 2;

        @media (min-width: 2000px) {
          right: 0.625vw;
          top: 0.625vw;
          padding: 0.8125vw 1.0625vw;
          font-size: 0.8125vw;
        }
      }
    }

    .modalBody {
      padding-top: 55px;
      padding-bottom: 60px;
      position: relative;
      overflow: hidden;
      z-index: 1;

      @media (min-width: 2000px) {
        padding-top: 1.25vw;
      }

      p {
        color: #9d978b;
        font-size: 16px;
        font-family: 'Rajdhani', sans-serif;
        font-weight: 400;

        @media (min-width: 2000px) {
          font-size: 1vw;
        }
      }

      .rightPan {
        overflow-y: auto;

        @media (min-width: 2000px) {
          height: 26.25vw;
        }

      }

      .actions {
        display: flex;
        padding-top: 10px;
        justify-content: space-between;
        align-items: center;
        position: absolute;
        bottom: 0;
        z-index: 10;
        left: 0;
        right: 0;
        width: 100%;
        background: url("../../assets/images/modalbg.png") center;
        background-size: cover;
      }
    }
  }
}

.modalMianTwo {
  max-width: 768px;
  margin: auto;

  @media (min-width: 2000px) {
    max-width: 48vw;
  }

  :global(.MuiBackdrop-root) {
    display: none;
  }

  :global(.MuiDialog-paper) {
    box-shadow: none;
    border-radius: 0;
    max-width: 100%;
    background-color: #e4e4e4;
    border-radius: 12px;
    box-shadow: 0px 3px 31px #45336f;

    &::-webkit-scrollbar {
      -ms-overflow-style: none;
      scrollbar-width: none;
      display: none;
    }
  }

  .modalTop {
    position: relative;

    figure {
      img {
        width: 100%;
        border-radius: 12px;
        height: auto;

        @media (max-width: 1199px) {
          object-fit: contain;
          object-position: top;
          height: auto;
        }
      }
    }

    button {
      position: absolute;
      right: 0;
      top: 20px;
      border-radius: 50%;

      @media (min-width: 2000px) {
        top: 1.25vw;
      }

      @media (max-width: 767px) {
        right: 0;
        top: 0;
      }

      svg {
        font-size: 30px;
        color: #272727;

        @media (min-width: 2000px) {
          font-size: 1.8125vw;
        }

        @media (max-width: 767px) {
          font-size: 29px;
        }
      }
    }
  }

  .modalBody {
    padding: 50px 70px;

    @media (min-width: 2000px) {
      padding: 3.125vw 4.375vw;
    }

    @media (max-width: 767px) {
      padding: 20px 15px;
    }

    h1 {
      letter-spacing: 8px;
      color: #45336f;
      font-size: 40px;
      font-weight: 400;

      @media (min-width: 2000px) {
        letter-spacing: 0.5vw;
        font-size: 2.5vw;
      }

      @media (max-width: 767px) {
        letter-spacing: 5px;
        font-size: 18px;
      }
    }

    h2 {
      letter-spacing: 5.5px;
      color: #45336f;
      font-size: 28px;
      font-weight: 400;

      @media (min-width: 2000px) {
        font-size: 0.34375vw;
        font-size: 1.75vw;
      }

      @media (max-width: 767px) {
        letter-spacing: 4.5px;
        font-size: 14px;
      }
    }

    .modalSocial {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 20px 0 30px;

      @media (min-width: 2000px) {
        padding: 1.25vw 0 1.875vw;
      }

      @media (max-width: 767px) {
        padding: 20px 0;
      }

      span {
        width: 60px;
        height: 60px;
        display: flex;
        border-radius: 50%;
        background: #45336f;
        align-items: center;
        justify-content: center;
        margin-right: 15px;

        @media (min-width: 2000px) {
          width: 3.75vw;
          height: 3.75vw;
          margin-right: 0.9375vw;
        }

        @media (max-width: 767px) {
          width: 40px;
          height: 40px;
        }

        :global(.MuiSvgIcon-root) {
          font-size: 30px;
          color: #fff;

          @media (min-width: 2000px) {
            font-size: 1.875vw;
          }

          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
      }

      p {
        letter-spacing: 5px;
        color: #45336f;
        font-size: 30px;
        margin-bottom: 0;

        @media (min-width: 2000px) {
          letter-spacing: 0.3125vw;
          font-size: 1.875vw;
        }

        @media (max-width: 767px) {
          letter-spacing: 3px;
          font-size: 15px;
        }
      }
    }

    p {
      letter-spacing: 2.5px;
      color: #272727;
      font-size: 17px;
      line-height: 2;

      @media (min-width: 2000px) {
        letter-spacing: 0.15625vw;
        font-size: 1.0625vw;
      }

      @media (max-width: 767px) {
        letter-spacing: 1.5px;
        font-size: 14px;
      }
    }
  }
}

.modalWrapperThree {
  // position: fixed !important;
  // padding: 15px 20px;
  // width: 100%;
  // max-width: 400px;
  // right: 20px;
  // background-size: auto !important;
  // background-position: 100% 100% !important;
  // bottom: 127px;
  // height: 500px !important;

  background: url("../../assets/images/modalwindow.png") no-repeat;
  background-size: 100%;
  overflow: hidden;
  position: relative;
  padding: 7px 0;
  position: fixed;
  bottom: 95px;
  height: 430px;
  right: 150px;
  width: 100%;
  max-width: 320px;

  @include mobile {
    bottom: 161px;
    right: 0;
  }

  @media (min-width: 2000px) {
    padding: 0.4375vw 0;
    bottom: 5.9375vw;
    height: 26.875vw;
    right: 9.375vw;
    max-width: 20vw;
  }

  .modalHeader {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    position: relative;
    padding: 7px 0;
    margin: 0 20px;

    @media (min-width: 2000px) {
      padding: 0.4375vw 0;
      margin: 0 1.25vw;
    }

    .topmodalClose {
      position: absolute;
      right: -5px;
      top: 7px;
      background-color: transparent;
      padding: 10px 13px;
      border: none;
      background: url("../../assets/images/btnbg.png") no-repeat;
      background-size: 35px;
      color: #9d978b;
      font-weight: 700;
    }

    .tab_btn {
      background-color: transparent;
      // flex-basis: 35%;
      // max-width: 35%;
      position: initial !important;
      border-radius: 0;
      padding: 0 15px;
      height: 34px;
      text-transform: uppercase;
      color: #dbdbdb;
      font-weight: 700;
      text-shadow: 1px 1px #000;
      font-size: 16px;
      font-family: "Rajdhani", sans-serif;

      @media (min-width: 2000px) {
        padding: 0 0.9375vw;
        height: 2.125vw;
        font-size: 1vw;
      }

      &.active_tab {
        background: url("../../assets/images/border-frame.png") no-repeat !important;
        background-size: cover !important;
        color: #fffbf7;
      }

      :global(.MuiTouchRipple-root) {
        display: none;
      }
    }
  }

  .modalBody {
    max-height: 88%;
    overflow-y: auto;
    padding-bottom: 20px;
    overflow-x: hidden;
    padding-right: 5px;
    margin: 15px 10px 0px 20px;

    @media (min-width: 2000px) {
      padding-bottom: 1.25vw;
      padding-right: 0.3125vw;
      margin: 0.9375vw 0.625vw 0 1.25vw;
    }

    &::-webkit-scrollbar {
      width: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #23211f;
    }

    &::-webkit-scrollbar-thumb {
      background: #464441;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .invent_box {
      width: 80px;
      height: 80px;
      background: url("../../assets/images/slot.png") no-repeat;
      background-size: cover;

      @media (min-width: 2000px) {
        width: 5vw;
        height: 5vw;
      }
    }
  }
}

.gamePic {
  img {
    display: block;
    width: 100%;
    height: auto;

    @media (min-width: 2000px) {
      width: 18.375vw;
      height: 19.5625vw;
    }
  }
}